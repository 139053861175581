
const siteSetting = {
    siteName: '8Content',
    apiUrl: 'https://goosemorning.8content.com/api',
    docUrl: 'https://goosemorning.8content.com/pdf',
    chatUrl: 'chat.iapi.8content.com',
    expUrl: 'https://goosemorning.8content.com/api/domain/willexp'
}

/*
const siteSetting = {
    siteName: '8Content',
    apiUrl: 'https://api-crm-demo.8content.com',
    docUrl: 'https://crm-pdf-demo.8content.com',
    chatUrl: 'chat.iapi.8content.com',
    expUrl: 'https://iapi.8content.com/domain/willexp'
}
*/


export default siteSetting