import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SideNav from '../components/sidenav';
import AppHeader from '../components/header';
import Button from '@mui/material/Button';
import axios from 'axios';
import siteSetting from '../components/siteSetting';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export default function Dashboard({ socket }) {

  const navigate = useNavigate();
  const [newMsg, setNewMsg] = useState(true);
  useEffect(() => {
    socket.on("messageResponse", () => {
      localStorage.setItem('newMsg', 'true');
      setNewMsg(false);
    });
  }, [socket])
  useEffect(() => {
    if (localStorage.getItem('newMsg')) {
      setNewMsg(false);
    }
  }, [])
  const [notiCount, setNotiCount] = useState(parseInt(localStorage.getItem('noticount')));
  useEffect(() => {
    const interval = setInterval(() => {
      if (parseInt(localStorage.getItem('noticount')) !== notiCount) {
        setNotiCount(parseInt(localStorage.getItem('noticount')))
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [notiCount]);
  const [pageData, setpageData] = useState(null);

  const [domainData, setDomainData] = useState(null);
  const [hostingData, setHostingData] = useState(null);


  const [stockFuckingData, setStockFuckingData] = useState(null);

  useEffect(() => {
    document.title = 'แดชบอร์ด - 8content'
    axios({
      method: 'get',
      url: siteSetting.apiUrl + '/dashboard',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      setpageData(response.data);

      const maxStock = Math.max(...response.data.stock.map(item => item.total_stock));
      const normalizedData = response.data.stock.map(item => ({
        ...item,
        normalizedStock: Math.min(100, (item.total_stock / maxStock) * 100)
      }));
      setStockFuckingData(normalizedData);

    });



    axios({
      method: 'get',
      url: 'https://iapi.8content.com/domain/willexp',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      setDomainData(response.data);
    });

    axios({
      method: 'get',
      url: 'https://iapi.8content.com/hosting/willexp',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      setHostingData(response.data);
    });




  }, []);

  function fixedNUM(num, precision = 2) {
    if (num === null) {
      return '0.00';
    }
    var roundedx = (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    var commasx = roundedx.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return commasx;
  }

  function checkExpdate(expdate) {
    var date = new Date(expdate) - new Date();
    var diff = Math.floor((date) / (24 * 3600 * 1000));
    if (diff > 0) {
      return (<span className="ml-2 bg-indigo-800 text-white text-xs font-semibold px-2.5 py-0.5 rounded">{diff} วัน</span>)
    } else if (diff === 0) {
      return (<span className="ml-2 bg-yellow-400 text-xs font-semibold px-2.5 py-0.5 rounded">{diff} วัน</span>)
    } else {
      return (<span className="ml-2 bg-red-700 text-white text-xs font-semibold px-2.5 py-0.5 rounded">{diff} วัน</span>)
    }
  }



  return (
    <>
      <div className="app-layout-modern flex flex-auto flex-col">
        <div className="flex flex-auto min-w-0">
          <SideNav />
          <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-main-bg-color justify-between max-h-screen bg-slate-50 ">
            <AppHeader
              pageTitle={`Dashboard`}
              disableFrom={true}
              notiCount={notiCount}
              newMsg={newMsg}
            />
            <div className="relative overflow-auto px-7 py-5 mb-auto" id="main-content">
              <div className='table-responsive py-7 min-h-80'>
                <div className="grid lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 gap-5">
                  <Card className='custom-card-bg relative' sx={{ backgroundColor: '#3730a3', padding: '1em' }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14, color: '#fff' }} gutterBottom>
                        สิ่งที่ต้องทำวันนี้
                      </Typography>
                      <Typography variant="h5" component="div" sx={{ color: '#fff' }} >
                        {pageData ? pageData.task : 0} รายการ
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button sx={{ color: '#fff', backgroundColor: 'rgb(255 255 255 / 13%)' }} size="small" onClick={() => navigate('/task')}>จัดการ</Button>
                    </CardActions>
                  </Card>

                  <Card className='custom-card-bg-2 relative' sx={{ backgroundColor: '#facc15', padding: '1em' }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        ใบเสนอราคาเดือนนี้
                      </Typography>
                      <Typography variant="h5" component="div">
                        {pageData ? pageData.docCount.quote : 0} รายการ
                      </Typography>
                      <Typography>
                        รวม {pageData ? fixedNUM(pageData.docSum.quote) : 0.00} บาท
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button sx={{ backgroundColor: 'rgba(55, 48, 163, 0.04)' }} size="small" onClick={() => navigate('/quotations')}>จัดการ</Button>
                    </CardActions>
                  </Card>

                  <Card className='relative' sx={{ padding: '1em' }}>
                    <CardContent>
                      <div className='flex'>
                        <div className='w-6/12'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            ใบแจ้งหนี้เดือนนี้
                          </Typography>
                          <Typography variant="h5" component="div">
                            {pageData ? pageData.docCount.iv : 0} รายการ
                          </Typography>
                          <Typography>
                            รวม {pageData ? fixedNUM(pageData.docSum.iv) : 0.00}
                          </Typography>
                        </div>
                        <div className='w-6/12'>
                          {pageData && <Line
                            options={{
                              responsive: true,
                              elements: {
                                point: {
                                  radius: 1
                                }
                              },
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                              scales: {
                                x: {
                                  ticks: {
                                    display: false,
                                  },
                                  grid: {
                                    drawBorder: false,
                                    display: false,
                                  },
                                  border: {
                                    display: false
                                  }
                                },
                                y: {
                                  ticks: {
                                    display: false,
                                    beginAtZero: true,
                                  },
                                  grid: {
                                    drawBorder: false,
                                    display: false
                                  },
                                  border: {
                                    display: false
                                  }
                                },
                              }
                            }}
                            data={{
                              labels: pageData.ivReport.iv,
                              datasets: [
                                {
                                  label: "ใบวางบิล/ใบแจ้งหนี้",
                                  backgroundColor: "#facc15",
                                  borderColor: "#facc15",
                                  pointBackgroundColor: "#facc15",
                                  pointBorderColor: "#facc15",
                                  data: pageData.ivReport.value
                                },
                              ],
                            }}
                          />}
                        </div>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Button sx={{ backgroundColor: 'rgba(55, 48, 163, 0.04)' }} size="small" onClick={() => navigate('/invoice')}>จัดการ</Button>
                    </CardActions>
                  </Card>


                  <Card className=' relative' sx={{ padding: '1em' }}>
                    <CardContent>
                      <div className='flex'>

                        <div className='w-6/12'>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            ใบเสร็จเดือนนี้
                          </Typography>
                          <Typography variant="h5" component="div">
                            {pageData ? pageData.docCount.re : 0} รายการ
                          </Typography>
                          <Typography>
                            รวม {pageData ? fixedNUM(pageData.docSum.re) : 0.00}
                          </Typography>
                        </div>
                        <div className='w-6/12'>
                          {pageData && <Line
                            options={{
                              responsive: true,
                              elements: {
                                point: {
                                  radius: 1
                                }
                              },
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                              scales: {
                                x: {
                                  ticks: {
                                    display: false,
                                  },
                                  grid: {
                                    drawBorder: false,
                                    display: false,
                                  },
                                  border: {
                                    display: false
                                  }
                                },
                                y: {
                                  ticks: {
                                    display: false,
                                    beginAtZero: true,
                                  },
                                  grid: {
                                    drawBorder: false,
                                    display: false
                                  },
                                  border: {
                                    display: false
                                  }
                                },
                              }
                            }}
                            data={{
                              labels: pageData.ivReport.iv,
                              datasets: [
                                {
                                  label: "ใบกำกับภาษี/ใบเสร็จรับเงิน",
                                  backgroundColor: "#3730a3",
                                  borderColor: "#3730a3",
                                  pointBackgroundColor: "#3730a3",
                                  pointBorderColor: "#3730a3",
                                  data: pageData.ivReport.re
                                },
                              ],
                            }}
                          />}
                        </div>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Button sx={{ backgroundColor: 'rgba(55, 48, 163, 0.04)' }} size="small" onClick={() => navigate('/receipt')}>จัดการ</Button>
                    </CardActions>
                  </Card>
                </div>

                <div className='flex flex-col h-full mt-8'>
                  <div className='h-full grid grid-cols-4 gap-5 flex-grow'>
                    <div className='col-span-3'>
                      <Card >
                        <CardContent>
                          {pageData && <Bar

                            options={{
                              responsive: true,
                              plugins: {
                                legend: {
                                  position: 'top',
                                  labels: {
                                    font: {
                                      family: 'Noto Sans Thai',
                                    }
                                  }
                                },
                              },
                            }}
                            data={{
                              labels: pageData.ivReport.iv,
                              datasets: [
                                {
                                  label: "ใบวางบิล/ใบแจ้งหนี้",
                                  backgroundColor: "#facc15",
                                  borderColor: "#facc15",
                                  pointBackgroundColor: "rgba(220, 220, 220, 1)",
                                  pointBorderColor: "#fff",
                                  data: pageData.ivReport.value
                                },
                                {
                                  label: "ใบกำกับภาษี/ใบเสร็จรับเงิน",
                                  backgroundColor: "#3730a3",
                                  borderColor: "#3730a3",
                                  pointBackgroundColor: "rgba(151, 187, 205, 1)",
                                  pointBorderColor: "#fff",
                                  data: pageData.ivReport.re
                                },
                              ],
                            }}
                          />}
                        </CardContent>
                      </Card>
                    </div>
                    <div className='h-full'>

                      {siteSetting.apiUrl === 'https://crm-api.8content.com' ? <Card sx={{ maxHeight: '60.4vh', overflowY: 'auto' }}>
                        <CardContent>
                          {domainData &&
                            <>
                              <h3 className='font-bold'>โดเมนใกล้หมดอายุ</h3>
                              {domainData.status === 200 ?
                                <List>
                                  {Object.entries(domainData.data).map(([k, item]) => (
                                    <div key={k}>
                                      <Divider />
                                      <ListItem disableGutters>
                                        <div className='flex justify-between w-full'>
                                          <div>
                                            <h4 className='text-md'>{item.name}</h4>
                                            <p className='text-gray-600 text-xs'>{item.exp}</p>
                                          </div>
                                          <p>{checkExpdate(item.exp)}</p>
                                        </div>
                                      </ListItem>
                                    </div>
                                  ))}
                                </List>
                                : 'ยังไม่มีโดเมนใกล้หมดอายุใน 30 วัน'}
                            </>
                          }

                          {hostingData &&
                            <>
                              <h3 className='font-bold mt-5'>โฮสติ้งใกล้หมดอายุ</h3>
                              {hostingData.status === 200 ?
                                <List>
                                  {Object.entries(hostingData.data).map(([k, item]) => (
                                    <div key={k}>
                                      <Divider />
                                      <ListItem disableGutters>
                                        <div className='flex justify-between w-full'>
                                          <div>
                                            <h4 className='text-md'>{item.name}</h4>
                                            <p className='text-gray-600 text-xs'>{item.exp}</p>
                                          </div>
                                          <p>{checkExpdate(item.exp)}</p>
                                        </div>
                                      </ListItem>
                                    </div>
                                  ))}
                                </List>
                                : 'ยังไม่มีโดเมนใกล้หมดอายุใน 30 วัน'}
                            </>
                          }
                        </CardContent>
                      </Card>

                        :

                        <Card className='h-full' sx={{ maxHeight: '100%', overflowY: 'auto' }}>
                          <CardContent>
                            {stockFuckingData &&
                              <>
                                <div className='flex justify-between w-full'>
                                  <h3 className='font-bold'>คลังสินค้า</h3>
                                  <div>คงเหลือ</div>
                                </div>
                                <List>
                                  {Object.entries(stockFuckingData).map(([k, item]) => (
                                    <div key={k}>
                                      <Divider />
                                      <ListItem disableGutters>
                                        <div className='w-full py-2'>
                                          <div className='flex justify-between w-full'>
                                            <h4 className='text-md'>{item.pr_sku}</h4>
                                            <p className='text-gray-600 text-xs'>{item.total_stock}</p>
                                          </div>

                                          <div className='w-full'>
                                            <LinearProgress variant="determinate" value={item.normalizedStock} />
                                          </div>
                                        </div>
                                      </ListItem>
                                    </div>
                                  ))}
                                </List>
                              </>
                            }
                          </CardContent>
                        </Card>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}