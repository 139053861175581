import * as React from "react";
const Logo = (props) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 800 493.8"
        {...props}
    >
        <defs>
            <style>
                {
                    "\n      .cls-1 {\n        fill: #000;\n      }\n\n      .cls-1, .cls-2, .cls-3 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #fec200;\n      }\n\n      .cls-3 {\n        fill: #231f20;\n      }\n    "
                }
            </style>
        </defs>
        <g>
            <g>
                <path
                    className="cls-2"
                    d="M333,289.2c-5.8-19.4-20.4-34.9-39.2-42.1.8,21.9,17.7,39.7,39.2,42.1Z"
                />
                <path
                    className="cls-2"
                    d="M335.7,307.5c-33.3-1.1-60.1-28.5-60.1-62.1s0-1.6,0-2.3c-1.6-.1-3.2-.2-4.8-.2-35.7,0-64.8,29.1-64.8,64.8s29.1,64.8,64.8,64.8,64.8-29.1,64.8-64.8,0-.1,0-.2Z"
                />
                <polygon
                    className="cls-2"
                    points="198.9 138.7 198.9 196.6 258.2 196.6 198.9 138.7"
                />
            </g>
            <g>
                <path
                    className="cls-3"
                    d="M561.4,460.2l2.8-2.8c-5.3-5.3-13.8-5.3-19.1,0-5.3,5.3-5.3,13.8,0,19.1,2.6,2.6,6.1,4,9.6,4s6.9-1.3,9.6-4l-2.8-2.8c-3.7,3.7-9.8,3.7-13.5,0-3.7-3.7-3.7-9.8,0-13.5,3.7-3.7,9.8-3.7,13.5,0Z"
                />
                <path
                    className="cls-3"
                    d="M582.3,453.4c-7.4,0-13.5,6.1-13.5,13.5s6.1,13.5,13.5,13.5,13.5-6.1,13.5-13.5-6.1-13.5-13.5-13.5ZM582.3,476.5c-5.3,0-9.6-4.3-9.6-9.6s4.3-9.6,9.6-9.6,9.6,4.3,9.6,9.6-4.3,9.6-9.6,9.6Z"
                />
                <path
                    className="cls-3"
                    d="M600.7,475.4c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5,2.5-1.1,2.5-2.5-1.1-2.5-2.5-2.5Z"
                />
                <path
                    className="cls-3"
                    d="M680.7,475.4c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5,2.5-1.1,2.5-2.5-1.1-2.5-2.5-2.5Z"
                />
                <path
                    className="cls-3"
                    d="M660.4,453.4h-8.6v27h8.6c7.4,0,13.5-6.1,13.5-13.5s-6.1-13.5-13.5-13.5ZM660.4,476.5h-4.6v-19.1h4.6c5.3,0,9.6,4.3,9.6,9.6s-4.3,9.6-9.6,9.6Z"
                />
                <polygon
                    className="cls-3"
                    points="620 453.4 616.1 453.4 616.1 480.4 630.8 480.4 630.8 476.5 620 476.5 620 453.4"
                />
                <polygon
                    className="cls-3"
                    points="636.6 453.4 629.3 453.4 629.3 457.4 636.6 457.4 636.6 480.4 636.6 480.4 640.5 480.4 640.5 480.4 640.5 457.4 647.8 457.4 647.8 453.4 640.5 453.4 636.6 453.4"
                />
                <polygon
                    className="cls-3"
                    points="606.9 488.1 609.6 488.1 611.7 478 608.2 478 606.9 488.1"
                />
                <path
                    className="cls-3"
                    d="M478.5,340c-8.5,0-16.5,2.6-23.1,7v-3.9c0-1.7-1.4-3.1-3.1-3.1h-12.3c-1.7,0-3.1,1.4-3.1,3.1v86.1c0,1.7,1.4,3.1,3.1,3.1h12.3c1.7,0,3.1-1.4,3.1-3.1v-47.7c0-12.7,10.3-23.1,23.1-23.1s23.1,10.3,23.1,23.1v47.7c0,1.7,1.4,3.1,3.1,3.1h12.3c1.7,0,3.1-1.4,3.1-3.1v-47.7c0-22.9-18.6-41.5-41.5-41.5Z"
                />
                <path
                    className="cls-3"
                    d="M146.3,340c-13.1,0-24.7,6-32.3,15.5-7.6-9.4-19.2-15.5-32.3-15.5s-16.5,2.6-23.1,7v-3.9c0-1.7-1.4-3.1-3.1-3.1h-12.3c-1.7,0-3.1,1.4-3.1,3.1v86.1c0,1.7,1.4,3.1,3.1,3.1h12.3c1.7,0,3.1-1.4,3.1-3.1v-47.7c0-12.7,10.3-23.1,23.1-23.1s23.1,10.3,23.1,23.1v47.7c0,1.7,1.4,3.1,3.1,3.1h12.3c1.7,0,3.1-1.4,3.1-3.1v-47.7c0-12.7,10.3-23.1,23.1-23.1s23.1,10.3,23.1,23.1v47.7c0,1.7,1.4,3.1,3.1,3.1h12.3c1.7,0,3.1-1.4,3.1-3.1v-47.7c0-22.9-18.6-41.5-41.5-41.5Z"
                />
                <path
                    className="cls-3"
                    d="M664.6,432.3c1.7,0,3.1-1.4,3.1-3.1v-47.7c0-22.9-18.6-41.5-41.5-41.5s-16.5,2.6-23.1,7v-3.9c0-1.7-1.4-3.1-3.1-3.1h-12.3c-1.7,0-3.1,1.4-3.1,3.1v86.1c0,1.7,1.4,3.1,3.1,3.1h12.3c1.7,0,3.1-1.4,3.1-3.1v-47.7c0-12.7,10.3-23.1,23.1-23.1s23.1,10.3,23.1,23.1v47.7c0,1.7,1.4,3.1,3.1,3.1h12.3Z"
                />
                <path
                    className="cls-3"
                    d="M550.8,340h12.3c1.7,0,3.1,1.4,3.1,3.1v86.1c0,1.7-1.4,3.1-3.1,3.1h-12.3c-1.7,0-3.1-1.4-3.1-3.1v-86.1c0-1.7,1.4-3.1,3.1-3.1Z"
                />
                <path
                    className="cls-3"
                    d="M404.7,340c-8.5,0-16.5,2.6-23.1,7v-3.9c0-1.7-1.4-3.1-3.1-3.1h-12.3c-1.7,0-3.1,1.4-3.1,3.1v86.1c0,1.7,1.4,3.1,3.1,3.1h12.3c1.7,0,3.1-1.4,3.1-3.1v-47.7c0-12.7,10.3-23.1,23.1-23.1s9.5,1.5,13.2,4.1c1.2.8,2.8.7,3.9-.4l8.8-8.8c1.3-1.3,1.2-3.5-.3-4.6-7-5.5-15.9-8.8-25.6-8.8Z"
                />
                <path
                    className="cls-3"
                    d="M556.9,303.8c-7.4,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5,13.5-6,13.5-13.5-6-13.5-13.5-13.5Z"
                />
                <path
                    className="cls-3"
                    d="M218.6,413.9h104.6c1.7,0,3.1,1.4,3.1,3.1v12.3c0,1.7-1.4,3.1-3.1,3.1h-104.6c-1.7,0-3.1-1.4-3.1-3.1v-12.3c0-1.7,1.4-3.1,3.1-3.1Z"
                />
                <path
                    className="cls-3"
                    d="M487.7,228.3h-4.6c-10,0-26.7-2.3-26.7-17.4s16.7-17.4,26.7-17.4,24.4,2,26.4,14.4c.3,1.7,1.7,3,3.4,3h13.6c2.1,0,3.7-1.8,3.5-3.9-1.7-16.5-14.7-34.1-47-34.1s-47.2,20.4-47.2,37.9,12.4,37.9,47.2,37.9h4.6c17,0,27.8,10.1,27.8,22s-10.8,22-27.8,22-28.9-8.5-31-19.2c-.3-1.7-1.7-2.9-3.4-2.9h-13.7c-2.1,0-3.7,1.8-3.5,3.9,2.4,21.7,24.6,38.7,51.6,38.7s48.3-19.1,48.3-42.6-19.7-42.6-48.3-42.6Z"
                />
                <path
                    className="cls-3"
                    d="M672.9,280.7c-1.3-1.3-3.4-1.3-4.7,0-9.1,8-20.6,12.3-32.8,12.3s-25.8-5.2-35.2-14.6c-8-8-13-18.4-14.2-29.5h109.2c6,0,10.7-5.1,10.2-11-.8-10.8-3.9-21.4-9.6-30.6-13.4-21.8-36-34.2-60.4-34.2s-36.4,7.3-49.7,20.6c-11.9,11.9-19.1,27.6-20.4,44.3-1.6,20.5,5.9,40.6,20.4,55.1,13.3,13.3,30.9,20.6,49.7,20.6s34-6.4,46.9-18c1.5-1.4,1.6-3.7.2-5.1l-9.6-9.6ZM588.6,226.3c2.5-6.8,6.4-13.1,11.6-18.3,9.4-9.4,21.9-14.6,35.2-14.6s25.8,5.2,35.2,14.6c5.2,5.2,9.2,11.5,11.6,18.3.3,1-.4,2-1.4,2h-90.8c-1,0-1.8-1-1.4-2Z"
                />
                <path
                    className="cls-3"
                    d="M399.9,245.5c0-34.3-27.9-62.1-62.1-62.1s-60.8,26.6-62.1,59.8c6.3.5,12.4,1.8,18.1,4,0-.6,0-1.1,0-1.7,0-24.3,19.7-44,44-44s44,19.7,44,44-19.7,44-44,44-3.2,0-4.8-.3c1.7,5.8,2.7,12,2.7,18.4.7,0,1.4,0,2.1,0,34.3,0,62.1-27.9,62.1-62.1Z"
                />
                <path
                    className="cls-3"
                    d="M792.1,404.1l-13.8-16.4c0-.5,0-1,0-1.5,0-25.5-20.7-46.1-46.1-46.1s-46.1,20.7-46.1,46.1,20.7,46.1,46.1,46.1,2.4,0,3.6-.2c13.2.8,24,9,24,18.6s-11.9,18.7-26.1,18.7-15.4-2.6-20.4-7.2c-1.1-1-2.9-1.1-4,0l-9.4,8c-1.4,1.2-1.4,3.3-.1,4.6,8.4,8.3,20.8,13.1,34,13.1,24.6,0,44.5-16.7,44.5-37.2s-6.6-22.2-16.8-29c4.3-3.6,8-7.9,10.8-12.9l18.3-1.3c1.7-.1,2.6-2.1,1.5-3.5ZM733.9,413.6v.2c-.5,0-1.1,0-1.6,0-15.3,0-27.7-12.4-27.7-27.7s12.4-27.7,27.7-27.7,27.7,12.4,27.7,27.7-10.7,25.9-24.5,27.5c-.5,0-1,0-1.6,0Z"
                />
                <path
                    className="cls-3"
                    d="M249.1,196.7h-103.8c-14.5,0-26.2,11.8-26.2,26.2h65.9v58.6c-16.8,12.4-37.4,19-58.4,18.8-51.7-.7-94.4-43.3-95.1-95-.4-26,9.5-50.5,27.7-69,18.3-18.5,42.6-28.7,68.6-28.7s50.9,10.4,69.2,29.3l1.9,1.8,59.3,58c9.3-9.6,9-24.8-.5-34.1l-43.4-42.5c-22.9-23.6-53.6-36.6-86.5-36.6s-63,12.7-85.8,35.9c-22.8,23.1-35.1,53.8-34.7,86.3.4,31.4,13,61.1,35.4,83.4,22.4,22.4,52,34.9,83.5,35.3.5,0,1,0,1.6,0,23.5,0,46.4-6.9,65.9-19.6.6-16.2,6.2-31.2,15.4-43.4v-38.5h13.8c14.5,0,26.2-11.8,26.2-26.2Z"
                />
                <path
                    className="cls-3"
                    d="M133.6,152.5c0,7.6,6.1,13.7,13.7,13.7s13.7-6.1,13.7-13.7-6.1-13.7-13.7-13.7-13.7,6.1-13.7,13.7Z"
                />
            </g>
        </g>
        <g>
            <path
                className="cls-1"
                d="M16,35.9c-3.1,0-5.3-.7-6.7-2.1-1.3-1.4-2-3.4-2-6.1v-13h4.1v13.2c0,1.6.4,2.8,1.1,3.6s1.9,1.2,3.5,1.2,2.7-.4,3.5-1.2,1.1-2,1.1-3.6v-13.2h4.1v13c0,2.6-.7,4.7-2,6.1-1.4,1.4-3.6,2.1-6.7,2.1Z"
            />
            <path
                className="cls-1"
                d="M36.9,35.9c-1.5,0-2.9-.2-4.3-.5-1.4-.3-2.5-.7-3.5-1.1l1.2-3.3c.5.2,1.1.5,1.8.7.7.2,1.4.4,2.2.6.8.2,1.6.2,2.5.2,1.3,0,2.3-.2,3.1-.7s1.2-1.1,1.2-2-.2-1-.5-1.4c-.3-.4-.8-.7-1.5-1.1-.7-.3-1.5-.6-2.5-1-1.5-.5-2.8-1-3.9-1.6s-1.9-1.3-2.4-2.1c-.6-.8-.8-1.8-.8-3s.3-2.2.9-3,1.5-1.5,2.8-2c1.2-.5,2.8-.7,4.6-.7s2.5.1,3.6.3c1.1.2,2,.5,2.6.9v3.3c-.4-.2-1-.4-1.6-.6-.7-.2-1.4-.4-2.1-.5s-1.6-.2-2.3-.2c-1.5,0-2.6.2-3.3.7-.6.4-1,1-1,1.6s.2.9.5,1.3c.3.4.9.7,1.6,1,.8.3,1.7.7,3,1.1,1.2.4,2.4.9,3.3,1.4,1,.5,1.8,1.2,2.3,2,.6.8.8,1.8.8,3s-.3,2.1-.8,3.1-1.4,1.7-2.6,2.3c-1.2.6-2.8.9-4.9.9Z"
            />
            <path className="cls-1" d="M26.8,11.4v-1.7l3.1-1.3h13.5v3.1h-16.5Z" />
            <path
                className="cls-1"
                d="M58.5,35.9c-2.2,0-3.9-.3-5.2-1s-2.3-1.6-2.9-2.9c-.6-1.2-.9-2.7-.9-4.5v-12.9h4v13c0,1.6.4,2.9,1.2,3.7.8.8,2.1,1.2,3.8,1.2s3-.4,3.7-1.2c.8-.8,1.1-2.1,1.1-3.7v-13h4.1v12.9c0,1.8-.3,3.3-.9,4.5-.6,1.2-1.6,2.2-2.9,2.9s-3.1,1-5.2,1ZM60.9,26.9c-1.1,0-2-.2-2.6-.5-.6-.3-1.1-.8-1.3-1.3-.3-.5-.4-1.1-.4-1.7s0-.8.2-1.3c.1-.4.3-.8.5-1.1l2.9.5c0,.1-.1.3-.2.5,0,.2,0,.4,0,.6,0,.3,0,.6.3.9.2.3.5.4.9.4h8.8v2.9h-9Z"
            />
            <path
                className="cls-1"
                d="M63,11.4c-1.1,0-2-.2-2.6-.5-.6-.3-1.1-.7-1.4-1.2-.3-.5-.4-1-.4-1.6s0-.8.2-1.3c.1-.5.3-.8.5-1.2l3.2.5c0,.1-.1.3-.2.5,0,.2,0,.4,0,.6,0,.3.1.6.3.8s.6.3,1.1.3h9.2v2.9h-9.8Z"
            />
            <path
                className="cls-1"
                d="M74.4,35.4V14.6h2.9l.6,3.3h.2c.4-1.1,1.2-2,2.3-2.7,1.1-.7,2.5-1,4.2-1s2.9.3,3.9.8c1.1.5,1.9,1.3,2.5,2.4.6,1.1.9,2.5.9,4.2v13.8h-4.1v-13.3c0-1.5-.4-2.6-1.1-3.3-.7-.7-1.7-1.1-3.1-1.1s-2.1.3-2.8.8c-.8.5-1.4,1.3-1.7,2.2-.4,1-.6,2-.6,3.3v11.4h-4.1Z"
            />
            <path
                className="cls-1"
                d="M107.5,35.4v-7c0-1.6.3-2.8,1-3.5s1.6-1.3,2.7-1.6v-.2s-4.1-1.6-4.1-1.6v-1.2c0-1.1.3-2.1,1-3.1.7-1,1.7-1.7,3-2.3,1.3-.6,2.9-.9,4.8-.9s3.1.3,4.5.8c1.3.5,2.4,1.3,3.2,2.4.8,1.1,1.2,2.5,1.2,4.3v13.8h-4.1v-13.5c0-1.6-.5-2.7-1.4-3.4-.9-.7-2.1-1-3.5-1s-2.6.3-3.4.9-1.2,1.3-1.3,2.3l3.9,1.9-.3,2c-.9,0-1.7.3-2.3,1s-.9,1.5-.9,2.8v7.1h-4.1Z"
            />
            <path
                className="cls-1"
                d="M119.4,45.5c-1.7,0-3.1-.3-3.9-1s-1.3-1.5-1.3-2.7v-.6c0-.4-.1-.7-.3-.8-.2,0-.5,0-.9,0l-.3-2.4c.4-.1.7-.2,1.2-.2.4,0,.7,0,1,0,1,0,1.7.2,2.1.6.4.4.6,1,.6,1.7v1.2c0,.5.1.8.4,1.1.3.3.7.5,1.4.5s1.2-.2,1.4-.5c.3-.3.4-.7.4-1.1v-3.4h3.5v3.7c0,1.2-.4,2.2-1.3,2.9s-2.2,1-3.9,1Z"
            />
            <path
                className="cls-1"
                d="M136.4,35.8c-2.2,0-3.9-.5-5.1-1.5-1.2-1-1.8-2.5-1.8-4.4s.8-3.7,2.3-4.9,3.9-1.9,7.3-2.1l4.5-.3v3.2l-4,.3c-2.3.2-3.8.6-4.7,1.1s-1.3,1.4-1.3,2.5.3,1.8,1,2.3c.7.5,1.6.7,2.7.7s.8,0,1.1,0c.3,0,.7-.1,1-.2l.5,2.9c-.5.2-1,.3-1.6.4-.6,0-1.2.1-1.9.1ZM142.9,35.4v-13.8c0-1.5-.4-2.6-1.1-3.3-.7-.6-1.9-1-3.5-1s-2.5.2-3.7.5c-1.2.4-2.3.8-3.3,1.4v-3.6c.7-.4,1.7-.7,3-1.1,1.3-.3,2.7-.5,4.4-.5s2.5.2,3.5.5,1.7.9,2.2,1.7c1,.4,1.6,1.1,2,1.9s.6,2,.6,3.3v13.8h-4ZM144.2,17.9l-2.2-2c.7-.2,1.4-.4,1.9-.9.6-.4.8-1,.8-1.7h3.8c0,.8-.2,1.5-.5,2.1-.4.6-.9,1.2-1.5,1.6s-1.4.7-2.3.9Z"
            />
            <path
                className="cls-1"
                d="M173.5,35.8c-1.7,0-3.1-.3-4.2-1s-1.9-1.6-2.4-2.7h-.2l-.3,3.3h-3.2V14.6h4.1v11.4c0,1.3.2,2.4.6,3.4.4.9,1,1.7,1.8,2.2.8.5,1.7.8,2.9.8s2.4-.4,3.1-1.1c.7-.7,1-1.9,1-3.3v-13.3h4.1v13.8c0,1.6-.3,3-.8,4.1-.6,1.1-1.4,1.9-2.4,2.5s-2.3.8-3.8.8Z"
            />
            <path
                className="cls-1"
                d="M194.1,35.9c-2.8,0-5-.7-6.4-2.2-1.4-1.5-2.1-3.7-2.1-6.7s0-1.1,0-1.7c0-.6,0-1.2.2-1.8h8.3v2.9h-4.5v.7c0,1.4.2,2.5.5,3.4s.8,1.4,1.4,1.7c.6.3,1.5.5,2.4.5s2-.2,2.7-.6c.8-.4,1.4-1.1,1.8-2.2.4-1.1.6-2.6.6-4.6s-.2-3.2-.6-4.4c-.4-1.1-1-2-1.9-2.5-.9-.6-2.1-.8-3.6-.8s-1.6,0-2.4.2c-.8.1-1.5.3-2.2.6-.7.3-1.4.6-2.1,1v-3.7c.5-.3,1.2-.5,1.9-.7.8-.2,1.6-.4,2.5-.5.9-.1,1.9-.2,2.8-.2,2.3,0,4.1.5,5.6,1.4,1.4.9,2.5,2.2,3.1,3.8.7,1.6,1,3.6,1,5.8s-.3,4.2-.9,5.8c-.6,1.6-1.6,2.8-2.9,3.7-1.4.8-3.1,1.3-5.4,1.3Z"
            />
            <path
                className="cls-1"
                d="M214.8,35.9c-1.5,0-2.9-.2-4.3-.5-1.4-.3-2.5-.7-3.5-1.1l1.2-3.3c.5.2,1.1.5,1.8.7.7.2,1.4.4,2.2.6.8.2,1.6.2,2.5.2,1.3,0,2.3-.2,3.1-.7s1.2-1.1,1.2-2-.2-1-.5-1.4c-.3-.4-.8-.7-1.5-1.1-.7-.3-1.5-.6-2.5-1-1.5-.5-2.8-1-3.9-1.6s-1.9-1.3-2.4-2.1c-.6-.8-.8-1.8-.8-3s.3-2.2.9-3,1.5-1.5,2.8-2c1.2-.5,2.8-.7,4.6-.7s2.5.1,3.6.3c1.1.2,2,.5,2.6.9v3.3c-.4-.2-1-.4-1.6-.6-.7-.2-1.4-.4-2.1-.5s-1.6-.2-2.3-.2c-1.5,0-2.6.2-3.3.7-.6.4-1,1-1,1.6s.2.9.5,1.3c.3.4.9.7,1.6,1,.8.3,1.7.7,3,1.1,1.2.4,2.4.9,3.3,1.4,1,.5,1.8,1.2,2.3,2,.6.8.8,1.8.8,3s-.3,2.1-.8,3.1-1.4,1.7-2.6,2.3c-1.2.6-2.8.9-4.9.9Z"
            />
            <path
                className="cls-1"
                d="M214.7,12.4c-.2-.3-.3-.7-.4-1.2-.1-.5-.2-.9-.2-1.4s.1-1.1.3-1.6c.2-.5.7-.9,1.3-1.2.6-.3,1.5-.5,2.5-.5h5.3v3h-4.1c-.5,0-.9,0-1.2.3-.3.2-.4.5-.4.9s0,.4,0,.6c0,.2.1.4.2.6l-3.4.5Z"
            />
            <path
                className="cls-1"
                d="M234.6,35.8c-1.5,0-2.8-.3-3.9-.8-1.1-.5-1.9-1.4-2.5-2.5-.6-1.1-.8-2.5-.8-4.2v-13.7h4.1v13.3c0,1.5.4,2.6,1.1,3.3.7.7,1.7,1.1,3.1,1.1s2.9-.6,3.8-1.7c.9-1.1,1.4-2.7,1.4-4.6v-11.4h4.1v20.8h-3.2l-.3-3.1h-.2c-.5,1-1.2,1.9-2.3,2.5-1.1.7-2.5,1-4.2,1Z"
            />
            <path className="cls-1" d="M228.3,11.4v-1.7l3.1-1.3h13.5v3.1h-16.5Z" />
            <path className="cls-1" d="M241.2,3.2V-3.2h3.7V3.2h-3.7Z" />
            <path
                className="cls-1"
                d="M254.6,35.4l-6.4-20.8h4.1l5.4,17.4h.4c.5,0,1.1-.1,1.6-.4s1-.6,1.3-1.1c.7-.8,1.2-1.8,1.5-3,.3-1.2.5-2.4.5-3.5,0-2-.4-3.5-1.3-4.6s-2.1-1.7-3.6-1.7-.6,0-.9,0c-.3,0-.5,0-.7.2l-.9-3.2c.6-.2,1.1-.3,1.6-.3.5,0,.9,0,1.3,0,1.3,0,2.4.2,3.4.6,1,.4,1.9.9,2.6,1.6.9.9,1.5,1.9,2,3.2s.6,2.7.6,4.3-.3,3.8-.9,5.3c-.6,1.5-1.4,2.8-2.3,3.7-.8.7-1.7,1.3-2.8,1.7-1.1.4-2.5.6-4.3.6h-2.2Z"
            />
            <path
                className="cls-1"
                d="M284.1,35.4v-9.1h-2.7v-2.9h6.5v8.8h.5c1,0,1.9-.2,2.6-.7.7-.5,1.2-1.3,1.6-2.4s.5-2.5.5-4.2-.2-3.2-.6-4.3c-.4-1.1-1.1-1.9-2-2.4-.9-.5-2.1-.7-3.6-.7s-2.4.2-3.5.5c-1.1.3-2.1.8-3.1,1.4v-3.6c.8-.4,1.8-.8,3.1-1.1,1.3-.3,2.6-.5,4.1-.5,2.4,0,4.2.4,5.7,1.3,1.4.8,2.5,2.1,3.1,3.7.6,1.6,1,3.5,1,5.9s-.3,4.1-.9,5.7-1.6,2.7-3,3.6-3.2,1.2-5.6,1.2h-3.7Z"
            />
            <path
                className="cls-1"
                d="M293.1,12.3c-1.3,0-2.4-.3-3.1-.9-.8-.6-1.2-1.5-1.2-2.6s.4-2,1.2-2.6c.8-.6,1.8-.9,3.1-.9s2.4.3,3.2.9c.8.6,1.2,1.5,1.2,2.6s-.4,2-1.2,2.6c-.8.6-1.9.9-3.2.9ZM293.1,10.4c.6,0,1-.1,1.4-.4s.5-.7.5-1.2-.2-.9-.5-1.2c-.3-.3-.8-.4-1.4-.4s-1,.1-1.3.4c-.3.3-.5.7-.5,1.2s.2.9.5,1.2.7.4,1.3.4ZM308.1,35.4v-14.5c0-1.2-.3-2.1-.8-2.6-.5-.6-1.5-.8-2.8-.8s-1.7.1-2.5.4c-.8.2-1.5.6-2.2,1v-3.5c.5-.3,1.3-.5,2.2-.8s2.1-.4,3.4-.4,2.5.2,3.5.6,1.8,1,2.3,1.9c.6.9.8,2.1.8,3.6v15.1h-4.1Z"
            />
            <path
                className="cls-1"
                d="M317.9,35.4v-7c0-1.6.3-2.8,1-3.5s1.6-1.3,2.7-1.6v-.2s-4.1-1.6-4.1-1.6v-1.2c0-1.1.3-2.1,1-3.1.7-1,1.7-1.7,3-2.3,1.3-.6,2.9-.9,4.8-.9s3.1.3,4.5.8c1.3.5,2.4,1.3,3.2,2.4.8,1.1,1.2,2.5,1.2,4.3v13.8h-4.1v-13.5c0-1.6-.5-2.7-1.4-3.4-.9-.7-2.1-1-3.5-1s-2.6.3-3.4.9-1.2,1.3-1.3,2.3l3.9,1.9-.3,2c-.9,0-1.7.3-2.3,1s-.9,1.5-.9,2.8v7.1h-4.1Z"
            />
            <path
                className="cls-1"
                d="M329.9,11.4c-1.1,0-2-.2-2.6-.5-.6-.3-1.1-.7-1.4-1.2-.3-.5-.4-1-.4-1.6s0-.8.2-1.3c.1-.5.3-.8.5-1.2l3.2.5c0,.1-.1.3-.2.5,0,.2,0,.4,0,.6,0,.3.1.6.3.8s.6.3,1.1.3h9.2v2.9h-9.8Z"
            />
            <path
                className="cls-1"
                d="M348.4,35.8c-2.1,0-3.7-.5-4.8-1.4-1.2-.9-2-2.2-2.5-3.8-.5-1.6-.7-3.4-.7-5.4s.2-4,.7-5.4c.5-1.4,1.1-2.5,2-3.4.8-.8,1.9-1.4,3-1.7,1.2-.3,2.5-.5,3.9-.5,2.1,0,3.8.3,5.1.9s2.3,1.5,2.9,2.6c.6,1.1.9,2.4.9,4v13.8h-4.1v-13.4c0-1.5-.4-2.6-1.1-3.3-.7-.8-2-1.1-3.7-1.1s-2,.2-2.9.6c-.8.4-1.5,1.2-2,2.3-.5,1.1-.7,2.7-.7,4.8s.1,2.6.4,3.7c.3,1.1.8,2,1.4,2.6.7.6,1.6.9,2.8.9s.7,0,1,0,.6-.1.9-.2l.4,3.1c-.4.2-.9.3-1.4.4-.5,0-1,.1-1.5.1Z"
            />
        </g>
    </svg>
);
export default Logo;
