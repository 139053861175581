import { NavLink } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
//import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
//import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Logo from './Logo';

export default function SideNav() {
    const liClass = 'flex space-x-4 items-center py-2.5 px-7 font-normal hover:text-blue hover:stroke-blue hover:border-l-4 ease-in-out duration-100 hover:bg-gray-smoke';
    const iconColor = '#595c60';
    return (
        <div className={'main-side-bar flex flex-col justify-between side-nav side-nav-transparent side-nav-expand border-r border-slate-150 lg:w-64 2xl:w-80 ' + localStorage.getItem('collapsed')}>
            <div className='' >
                <div className="side-nav-header border-b border-slate-150">
                    <div className="logo px-4 py-4">
                        <div className="svg-container">
                            <Logo
                                height='47px'
                            />
                        </div>
                    </div>
                </div>
                <div className="side-nav-content py-4 font-bold text-gray-dark" style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'scroll' }}>
                    <ul>
                        <li className='main-page-home'>
                            <NavLink to="/" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <DashboardOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    แดชบอร์ด
                                </span>
                            </NavLink>
                        </li>
                    </ul>

                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>รายชื่อ</span>
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/leads" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <FeedOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    รายชื่อ
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/lead-groups" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <FolderCopyOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    กลุ่มรายชื่อ
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/customer" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <PeopleAltOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ลูกค้า / ผู้ติดต่อ
                                </span>
                            </NavLink>
                        </li>
                    </ul>

                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>การขาย</span>
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/quotations" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <RequestPageOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ใบเสนอราคา
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/invoice" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <AssignmentOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ใบแจ้งหนี้
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/receipt" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <ReceiptLongOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ใบเสร็จ
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/products" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <Inventory2OutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    สินค้า / บริการ
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/report" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <ShowChartOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    รายงาน
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                    {/*
                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>เอกสารซื้อ</span>
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/purchase-orders" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <RequestPageOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ใบสั่งซื้อ
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/purchases" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <InventoryOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ใบรับสินค้า
                                </span>
                            </NavLink>
                        </li>
                    </ul>

                    
                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>ค่าใช้จ่าย</span>
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/expenses" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <TextSnippetOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ค่าใช้จ่าย
                                </span>
                            </NavLink>
                        </li>
                       <li>
                            <NavLink to="/withholding-tax" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <AssignmentOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    หัก ณ ที่จ่าย
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                    */}

                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>งาน</span>
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/task" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <AssignmentTurnedInOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    สิ่งที่ต้องทำ
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/carlendar" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <DateRangeOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    ปฏิทิน
                                </span>
                            </NavLink>
                        </li>
                    </ul>


                    <div className="left-sidebar-title px-7 pt-5 pb-4 text-sm uppercase font-normal">
                        <span>พนักงาน</span>
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/user" className={liClass}>
                                <span className='menu-svg-warp'>
                                    <PermIdentityOutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    พนักงาน
                                </span>
                            </NavLink>
                        </li>
                    </ul>



                </div>
            </div>


            <div>
                <ul className='mb-2'>
                    <li className='main-page-home'>
                        <NavLink to="/logout" className={liClass}>
                            <span className='menu-svg-warp'>
                                <LogoutOutlinedIcon sx={{ color: iconColor }} />
                            </span>
                            <span className='menu-link-warp'>
                                ออกจากระบบ
                            </span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}